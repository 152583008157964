import './ProductEditModals.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {DEF_VATS, setProductPrices} from '../../pages/Product/slice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';

interface PriceProps {
    closeModal(): void;
}

const VatModal = ({closeModal}: PriceProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const product: IProduct = useAppSelector((state) => state.product.product!);
    const merchant: IMerchant = useAppSelector((state) => state.app.merchant!);

    const [taxClass, setTaxClass] = useState(product.taxClass);

    const onSave = () => {
        dispatch(setProductPrices({
            defPrice: product.productPrice,
            price: product.totalPrice,
            prices: (product.prices || '').split('|').map((i) => i.trim()).filter(Boolean),
            taxClass,
            serviceFee: +merchant.serviceFee,
            sirupayFee: +merchant.sirupayFee
        }));
        closeModal();
    };

    const vatSwitchers = DEF_VATS.filter(i => i !== 0).map((tax, i) => {
        return (
            <label className="vat-switch" key={i}>{tax}%
                <input
                    type="radio"
                    name="vat-rates"
                    value={i + 1}
                    checked={taxClass === i + 1}
                    onChange={() => {
                        setTaxClass(i + 1);
                    }}
                />
                <span className="vat-switch-name"/>
            </label>
        );
    });

    return (
        <div className="vat-classes-modal">

            <p>Choose product vat rate</p>
            <div className="price-modal-vats">
                {vatSwitchers}
            </div>

            <div className="d-flex justify-content-between">
                <button className="btn btn-success price-modal-btn" onClick={onSave}>{t('button.save')}</button>

                <button className="btn btn-dark price-modal-btn" onClick={closeModal}>{t('button.close')}</button>
            </div>
        </div>
    );
};

export default VatModal;
