import '../../ErrorModal/ErrorModal.css';
import './GenSuccessModal.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const useGenSuccessModal = () => {
    const [isGenSuccess, setIsGenSuccess] = useState(false);

    const showGenSuccessModal = () => {
        setIsGenSuccess(true);
    };

    const closeGenSuccessModal = () => {
        setIsGenSuccess(false);
    };

    return {
        isGenSuccess,
        showGenSuccessModal,
        closeGenSuccessModal,
    };
};

interface GenSuccessModalProps {
    onClose: Function;
}

const GenSuccessModal = ({onClose}: GenSuccessModalProps) => {
    const {t} = useTranslation();

    return (
        <div className="sm-overlay">
            <div className="sm-popup">
                <button type="button" className="sm-popup-close" onClick={() => onClose()}/>

                <div className="sm-popup-icon sm-popup-icon-success"/>

                <div className="sm-popup-title">{t('codes.generated')}</div>

                <div className="sm-popup-subtitle">{t('codes.saved')}</div>
            </div>
        </div>
    );
};

export default GenSuccessModal;