import './ProductsPage.css';

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProductCard from '../../components/ProductCard';
import Spinner from '../../components/Spinner';
import config from '../../config';
import {useAppSelector} from '../../redux/hooks';
import apiClient from '../../store/apiClient';

const ProductsPage = () => {
    const {t} = useTranslation();

    const [products, setProducts] = useState<IProduct[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const merchant = useAppSelector((state) => state.app.merchant!);

    const merchantUrl = useMemo(() => `${config.site.url}/${merchant.urlName}`, [config.site.url, merchant.urlName]);

    const genQrCode = async (id: number) => {
        const product = products.find((i) => i.id === id);

        if (product) {
            product.qrCode = await apiClient.genQrCode(product.id);

            setProducts(products.map((i) => i.id === product.id ? product : i));
        }
    };

    const refreshPage = useCallback(async () => {
        const tmp = await apiClient.getProducts();

        setProducts(tmp);

        setIsDataLoading(false);
    }, []);

    useEffect(() => {
        refreshPage();
    }, []);

    const renderProductsList = (products: IProduct[]) => {
        return products.map((product) => {
            return <ProductCard
                merchantUrl={merchantUrl}
                product={product}
                key={product.id}
                genQrCode={genQrCode}
                getVoucherPdf={getVoucherPdf}
            />;
        });
    };

    const getVoucherPdf = async (id: number, name: string) => {
        setIsDataLoading(true);

        try {
            await apiClient.getVoucherPdf(id, name);
        } catch (e) {

        } finally {
            setIsDataLoading(false);
        }
    };

    return (
        <>
            {isDataLoading &&
                <Spinner/>
            }

            <div className="main">
                <div className="main-header">
                    <div>
                        <h2 className="main-title">{t('products.title', {merchant: merchant.name})}</h2>
                        <a className="shop-link" target="_blank" rel="noopener noreferrer"
                           href={merchantUrl}>{merchantUrl}</a>
                    </div>

                    <NavLink className="main-link" to="/product/0">+ {t('products.add')}</NavLink>
                </div>
                {products && renderProductsList(products)}
            </div>
        </>
    );
};

export default ProductsPage;
