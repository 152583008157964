import './ProductEditModals.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import Slider from '@material-ui/core/Slider';

import {getCompressedImage, getCroppedImg} from '../../services/imageService';
import {useAppDispatch} from '../../redux/hooks';
import {setProductImage} from '../../pages/Product/slice';

interface ImageCropModalProps {
    image: string;
    closeModal(): void;
}

const ImageCropperModal = ({image, closeModal}: ImageCropModalProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [zoom, setZoom] = useState<number>(1);
    const [minZoom, setMinZoom] = useState<number>(1);
    const [maxZoom, setMaxZoom] = useState<number>(minZoom + 3);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [cropSize] = useState({width: 412, height: 412});
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

    const onSave = async () => {
        try {
            const croppedImage: string = await getCroppedImg(image, croppedAreaPixels);
            const compressedImage: any = await getCompressedImage(croppedImage);

            dispatch(setProductImage({
                image: croppedImage,
                imageBody: compressedImage,
            }));
            closeModal();
        } catch (e) {
            console.log('Image saving failed: ', e);
        }
    };

    const setImageZoom = ({width, height}) => {
        const minSide = Math.min(width, height);

        if (414 / minSide > 1) {
            const zoom = Math.ceil(414 / minSide * 100) / 100;

            setZoom(zoom);
            setMinZoom(zoom);
            setMaxZoom(zoom + 3);
        } else {
            const zoom = Math.ceil(minSide / 414 * 100) / 100;

            setZoom(1);
            setMinZoom(1 / zoom);
            setMaxZoom(3 + 1 / zoom);
        }
    };

    return (
        <div className="img-cropper-wrap">
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                cropSize={cropSize}
                onCropChange={setCrop}
                onCropComplete={(croppedArea: Area, croppedAreaPixels: Area) => setCroppedAreaPixels(croppedAreaPixels)}
                onZoomChange={setZoom}
                onMediaLoaded={setImageZoom}
                zoomWithScroll={false}
                style={{
                    containerStyle: {borderRadius: '2.6rem', height: 'auto', top: '11%'},
                    mediaStyle: {width: 'auto', borderRadius: '2.6rem'},
                    cropAreaStyle: {borderRadius: '2.6rem'},
                }}
            />

            <div className="slider-wrap">
                <Slider
                    orientation="vertical"
                    value={zoom}
                    min={minZoom}
                    max={minZoom + 3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Array.isArray(zoom) ? zoom[0] : zoom)}
                    classes={{root: 'slider'}}
                />
            </div>

            <button type="button" className="btn btn-success btn-image btn-image-save" onClick={() => onSave()}>{t('button.save')}</button>
            <button type="button" className="btn btn-dark btn-image btn-image-cancel" onClick={closeModal}>{t('button.cancel')}</button>
        </div>
    );
};

export default ImageCropperModal;