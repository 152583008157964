import imageCompression from 'browser-image-compression';

const createImage = (url) => {
    const image: HTMLImageElement = new Image();
    image.src = url;
    return image;
};


/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param imageSrc
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export const getCroppedImg = async (imageSrc, pixelCrop): Promise<string> => {
    const image: HTMLImageElement = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx!.translate(safeArea / 2, safeArea / 2);
    // ctx.rotate(getRadianAngle(rotation))
    ctx!.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx!.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5,
    );
    const data = ctx!.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx!.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            // @ts-ignore
            resolve(URL.createObjectURL(file));
        }, 'image/jpeg');
    });
};

export const getImageBody = async (imageSrc) => {
    const image: HTMLImageElement = await createImage(imageSrc);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.height = image.height;
    canvas.width = image.width;

    ctx!.drawImage(image, 0, 0);

    // As Base64 string
    return canvas.toDataURL('image/jpeg');
};

export const getCompressedImage = async (fullSizeImage): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const options = {maxSizeMB: 0.5, maxWidthOrHeight: 960};

        const img = await fetch(fullSizeImage);
        const blobImg = await img.blob();
        // @ts-ignore
        const compressedFile = await imageCompression(blobImg, options);

        const reader = new FileReader();
        reader.onload = async () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(compressedFile);
    });
};

export const initDownload = (name: string, href: string) => {
    const link = document.createElement('a'); // Or maybe get it from the current document
    document.body.appendChild(link);
    link.href = href;
    link.download = name;
    link.innerHTML = '';
    link.click();
    link.remove();
};