import React, {useMemo} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {fetchMerchant} from '../../redux/appSlice';

const PrivateRoute = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {isAuthenticated} = useAuth0();
    const {app} = useAppSelector((state) => state);

    return useMemo(() => {
        if (!isAuthenticated) {
            return null;
        } else if (app.merchant === undefined) {
            dispatch(fetchMerchant());
            return null;
        } else if (!app.merchant && location.pathname !== '/register/') {
            return <Navigate to="/register" replace/>;
        } else if (app.merchant && location.pathname === '/register/') {
            return <Navigate to="/products" replace/>;
        } else {
            return <Outlet/>;
        }
    }, [isAuthenticated, app.merchant, location.pathname]);
};

export default PrivateRoute;
