import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import apiClient from '../../../store/apiClient';

interface SliceType {
    loading: boolean,
    companies: ICompany[];
    list: ITransaction[];
    filters: {
        id?: string,
        status?: string,
        gateway?: string,
        from?: string,
        to?: string,
        product?: string,
        companyId?: number,
        clientPhone?: string,
        transfer?: string,
        transferId?: string
    };
}

const initialState: SliceType = {
    loading: false,
    companies: [],
    list: [],
    filters: {
        status: 'success',
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
    }
};

export const fetchCompanies = createAsyncThunk('companies/fetch', async (): Promise<ICompany[]> => {
    return await apiClient.getCompanies();
});

export const getTransactions = createAsyncThunk('transactions/get', async (filters: SliceType['filters'] = {}) => {
    return await apiClient.getTransactions(filters);
});

const slice = createSlice({
    name: 'adminTransactionsSlice',
    initialState,
    reducers: {
        setFilter: (state, {payload, type}: PayloadAction<SliceType['filters']>) => {
            for (const [key, value] of Object.entries(payload)) {
                state.filters[key] = value;
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.companies = [];
                state.loading = true;
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.companies = action.payload;
                state.loading = false;
            })
            .addCase(fetchCompanies.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getTransactions.pending, (state) => {
                state.list = [];
                state.loading = true;
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(getTransactions.rejected, (state) => {
                state.loading = false;
            })
    },
});

export const {setFilter} = slice.actions;

export default slice.reducer;