import './SettingsPage.css';

import React from 'react';
import {useLocation} from 'react-router-dom';


const SettingsPage = () => {

    let location = useLocation();

    let setNavClassName = (path) => {
        return `${location.hash}` === path ? 'settings-nav-name settings-nav-name_active' : 'settings-nav-name';
    };

    return (
        <>
            <div className="settings-header">
                <div className="settings-nav">
                    {/*<HashLink to="#general" className={setNavClassName('#general')}>General</HashLink>*/}
                    {/*<HashLink to="#subscription" className={setNavClassName('#subscription')}>Subscription</HashLink>*/}
                    {/*<HashLink to="#payments" className={setNavClassName('#payments')}>Payments</HashLink>*/}
                    {/*<HashLink to="#tracking" className={setNavClassName('#tracking')}>Tracking</HashLink>*/}
                    {/*<HashLink to="#reports" className={setNavClassName('#reports')}>Reports</HashLink>*/}
                </div>
            </div>

            <div className="settings-list">
                <section className="setting" id="general">
                    <h4 className="setting-title">Merchant info</h4>

                    <p className="setting-description">Nullam et porttitor est.</p>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">Company name:</label>
                        <input className="setting-input" type="text" placeholder="Company 100"/>
                    </div>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_address">Company address:</label>
                        <input className="setting-input" type="text" placeholder="Tampere testikatu 2"/>
                    </div>
                </section>

                <section className="setting" id="subscription">
                    <h4 className="setting-title">Subscription</h4>

                    <p className="setting-description">Nullam et porttitor est.</p>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">Company name:</label>
                        <input className="setting-input" type="text" placeholder="Company 100"/>
                    </div>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_address">Company address:</label>
                        <input className="setting-input" type="text" placeholder="Tampere testikatu 2"/>
                    </div>
                </section>

                <section className="setting setting_switcher" id="payments">
                    <h4 className="setting-title">Payments</h4>

                    <div className="setting-type">
                        <span className="setting-name">Mobile payment</span>

                    </div>

                    <p className="setting-description">Nullam et porttitor est.</p>

                    <div className="setting-type">
                        <span className="setting-name">Bank payment</span>

                    </div>

                    <p className="setting-description">Nullam et porttitor est.</p>

                </section>

                <section className="setting setting_switcher" id="tracking">
                    <h4 className="setting-title">Tracking details</h4>

                    <div className="setting-type">
                        <span className="setting-name">Facebook pixel</span>

                    </div>

                    <p className="setting-description">Nullam et porttitor est.</p>

                    <div className="setting-type">
                        <span className="setting-name">Google Analytics</span>

                    </div>

                    <p className="setting-description">Nullam et porttitor est.</p>
                </section>

                <section className="setting setting_danger" id="reports">
                    <h4 className="setting-title">Danger zone</h4>

                    <div className="d-flex">
                        <p className="setting-description">Nullam et porttitor est.</p>
                        <button type="button" className="btn btn-danger setting-btn_danger">delete</button>
                    </div>
                </section>

            </div>
        </>
    );
};

export default SettingsPage;