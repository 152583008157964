const defConfig = {
    api: {
        url: 'https://sps.siru.ost/json-rpc.php',
    },
    site: {
        url: 'https://sps.siru.ost',
    },
    auth0: {
        domain: 'sirumobile.eu.auth0.com',
        clientId: 't5bkX4AlvAz15fzMNCBPdswpgUu8WiqO',
        audience: 'https://sps.siru.ost/api',
    },
};

const envConfig = {
    'localhost': {},
    'sps-merchant.devel.offsiteteam.com': {
        api: {
            url: 'https://sps.devel.offsiteteam.com/json-rpc.php',
        },
        site: {
            url: 'https://sps.devel.offsiteteam.com',
        },
        auth0: {
            domain: 'sirumobile.eu.auth0.com',
            clientId: 't5bkX4AlvAz15fzMNCBPdswpgUu8WiqO',
            audience: 'https://sps.siru.ost/api',
        },
    },
    'sps-merchant-test.sirupay.com': {
        api: {
            url: 'https://sps-test.sirupay.com/json-rpc.php',
        },
        site: {
            url: 'https://sps-test.sirupay.com',
        },
        auth0: {
            domain: 'sirumobile.eu.auth0.com',
            clientId: 't5bkX4AlvAz15fzMNCBPdswpgUu8WiqO',
            audience: 'https://sps.siru.ost/api',
        },
    },
    'merchant.shop.sirumobile.com': {
        api: {
            url: 'https://shop.sirumobile.com/json-rpc.php',
        },
        site: {
            url: 'https://shop.sirumobile.com',
        },
        auth0: {
            domain: 'sirumobile.eu.auth0.com',
            clientId: 't5bkX4AlvAz15fzMNCBPdswpgUu8WiqO',
            audience: 'https://sps.siru.ost/api',
        },
    },
    'food-admin.sirupay.com': {
        api: {
            url: 'https://food.sirupay.com/json-rpc.php',
        },
        site: {
            url: 'https://food.sirupay.com',
        },
        auth0: {
            domain: 'food-admin.eu.auth0.com',
            clientId: 'OTfGTVCXTfHhlAU2zeFUqeMyZfj409dH',
            audience: 'https://food.sirupay.com/api',
        },
    },
    'merchant.fuudo.fi': {
        api: {
            url: 'https://app.fuudo.fi/json-rpc.php',
        },
        site: {
            url: 'https://app.fuudo.fi',
        },
        auth0: {
            domain: 'sirumobile.eu.auth0.com',
            clientId: 'ihN4M5PZhVCmvb2hns8RjHMKrf8FANti',
            audience: 'https://sps.siru.ost/api',
        },
    }
    // and so on...
};

class AppConfig {
    config: any;

    constructor() {
        // first apply default config (use its copy!)
        this.config = JSON.parse(JSON.stringify(defConfig));

        // now check if there are any overrides available for the environments. If so - apply them
        const env = AppConfig.env;
        if (env) {
            AppConfig.deepMerge(this.config, envConfig[env]);
        }
    }

    // merge recursively
    static deepMerge(target, source) {
        Object.keys(source) // start iterating through the own keys of the object
            .forEach(k => source[k] instanceof Object
                ? AppConfig.deepMerge((target[k] || (target[k] = {})), source[k])
                : target[k] = source[k],
            );
    }

    static get env(): string {
        return window.location.hostname;
    }
}

const appConfig = new AppConfig();

export default appConfig.config;

export const getEnv = AppConfig.env;
