

export const priceFloor = (value) => Math.floor(value * 100) / 100;

export const priceRound = (value) => Math.round(value * 100) / 100;

export const priceCeil = (value) => Math.ceil(value * 100) / 100;

export const format = (value: number | string): string => (+value).toFixed(2);

