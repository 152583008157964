import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiClient from '../../../store/apiClient';

interface SliceType {
    loading: boolean,
    list: ICompany[];
}

const initialState: SliceType = {
    loading: false,
    list: [],
};

export const fetchCompanies = createAsyncThunk('companies/fetch', async (): Promise<ICompany[]> => {
    return await apiClient.getCompanies();
});

const slice = createSlice({
    name: 'adminCompaniesSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.list = [];
                state.loading = true;
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(fetchCompanies.rejected, (state) => {
                state.loading = false;
            })
    },
});

export default slice.reducer;