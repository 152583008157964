import './GenCodesComponent.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Slider from '@material-ui/core/Slider';

import GenSuccessModal, {useGenSuccessModal} from '../GenSuccessModal';
import Spinner from '../../Spinner';
import apiClient from '../../../store/apiClient';

interface ThisComponentProps {
    id: number;
}

const GenCodesComponent = ({id}: ThisComponentProps) => {
    const {t} = useTranslation();

    const [codeLength, setCodeLength] = useState<any>(6); //TODO type
    const [isNumeric, setIsNumeric] = useState(true);
    const [isLiteral, setIsLiteral] = useState(true);
    const [quantity, setQuantity] = useState(200);

    const [isGenerating, setIsGenerating] = useState(false);

    const genCodes = async () => {
        if (!isLiteral && !isNumeric) {
            alert(t('codes.alert'));
            return;
        }

        setIsGenerating(true);

        const type = isLiteral && isNumeric ? 'alphanumeric' : isLiteral ? 'alpha' : 'numeric';

        try {
            const result = await apiClient.genProductCodes(id, quantity, codeLength, type);

            handleCodes(result.good);

            showGenSuccessModal();
        } catch (e) {
            console.log(e);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleCodes = (codes: string[]) => {
        let csvContent = 'data:text/csv;charset=utf-8,' + codes.join('\n');
        const encodedUri = encodeURI(csvContent);

        const link = createDownloadLink(encodedUri);

        link.click();
    };

    const createDownloadLink = (encodedUri) => {
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'codes.csv');
        document.body.appendChild(link);

        return link;
    };

    const {
        isGenSuccess,
        showGenSuccessModal,
        closeGenSuccessModal,
    } = useGenSuccessModal();

    return (
        <div className="generate-page">
            {isGenSuccess &&
                <GenSuccessModal onClose={closeGenSuccessModal}/>
            }

            {isGenerating &&
                <Spinner/>
            }
            <div className="d-flex align-items-start">
                <div className="codes-length">
                    <h3 className="generate-page-title">{t('codes.length')}</h3>
                    <span>{t('codes.lengthLimit')}</span>
                    <div className="d-flex align-items-center">
                        <input type="number" className="length-input" style={{}} value={codeLength} onChange={(e) => {
                            setCodeLength(+e.target.value);
                        }}/>
                        <Slider
                            value={codeLength}
                            min={4}
                            max={16}
                            step={1}
                            aria-labelledby="Zoom"
                            onChange={(e, length) => {
                                setCodeLength(length);
                            }}
                            classes={{root: 'length-slider'}}
                        />
                    </div>
                </div>
                <div className="characters">
                    <h3 className="generate-page-title">{t('codes.characters')}</h3>
                    <span>{t('codes.charactersNote')}</span>

                    <div className="characters-buttons">
                        <div className="character-btn-wrap">
                            <span className="character-btn-label">{t('codes.letters')}</span>
                            <label className="switcher">
                                <input type="checkbox" onChange={() => setIsLiteral(!isLiteral)} checked={isLiteral}/>
                                <div className="switcher-btn character-btn"/>
                            </label>
                        </div>

                        <div className="character-btn-wrap">
                            <span className="character-btn-label">{t('codes.numbers')}</span>
                            <label className="switcher">
                                <input type="checkbox" onChange={() => setIsNumeric(!isNumeric)} checked={isNumeric}/>
                                <div className="switcher-btn character-btn"/>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="codes-quantity">
                    <h3 className="generate-page-title">{t('codes.quantity')}</h3>
                    <span>{t('codes.quantityMax')}</span>
                    <input type="number" className="length-input codes-quantity-input" style={{}} onChange={(e) => {
                        if (+e.target.value > 1000) {
                            return;
                        }
                        setQuantity(+e.target.value);
                    }} value={quantity}/>
                </div>
            </div>
            <button className="btn btn-success btn-gen" onClick={genCodes}>{t('button.generate.codes')}</button>
        </div>
    );
};

export default GenCodesComponent;