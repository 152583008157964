import '../SettingsPage/SettingsPage.css';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import config from '../../config';
import {useAuth0} from '@auth0/auth0-react';
import apiClient from '../../store/apiClient';
import {useAppDispatch} from '../../redux/hooks';
import {registerMerchant} from '../../redux/appSlice';

const MerchantRegistration = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {user} = useAuth0();
    const [name, setName] = useState('');
    const [urlName, setUrlName] = useState('');

    useEffect(() => setName(user?.name || ''), [user]);

    useEffect(() => {
        async function updateUrlName() {
            const result = await apiClient.previewMerchantUrlName(name);
            setUrlName(result);
        }

        name && name.length > 3 ? updateUrlName() : setUrlName('');
    }, [name]);

    const onSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        try {
            dispatch(registerMerchant({
                authId: user?.sub || '',
                name,
            }));
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="settings-list">
                <section className="setting" id="general">
                    <h4 className="setting-title">{t('register.welcome')}</h4>
                    <p className="setting-description">{t('register.instruction')}</p>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">{t('register.name')}:</label>
                        <input type="text"
                               className="setting-input"
                               placeholder={t('register.namePlaceholder')}
                               value={name || ''}
                               required={true}
                               minLength={4}
                               maxLength={100}
                               onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">{t('register.link')}:</label>
                        {config.site.url}/<b>{urlName}</b>
                    </div>
                    <button type="submit" className="btn btn-lg btn-secondary">{t('button.register')}</button>
                </section>

            </div>
        </form>
    );
};

export default MerchantRegistration;
