import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiClient from '../../../store/apiClient';

interface SliceType {
    loading: boolean,
    companies: ICompany[];
}

const initialState: SliceType = {
    loading: false,
    companies: [],
};

export const fetchCompanies = createAsyncThunk('companies/fetch', async (): Promise<ICompany[]> => {
    return await apiClient.getCompanies();
});

const slice = createSlice({
    name: 'adminReportsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.companies = action.payload;
            })
            .addCase(fetchCompanies.rejected, (state) => {
                state.companies = [];
            })
    },
});

export default slice.reducer;