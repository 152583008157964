import './UploadArea.css';

import React, {DragEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

export const useUploadArea = (setUploadedCodes: Function) => {
    const [isDragging, setDragging] = useState(false);

    let dragCounter = 1;

    const preventDefaultBehavior = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e: DragEvent) => {
        preventDefaultBehavior(e);

        dragCounter++;
        setDragging(true);
    };

    const handleDragOut = (e: DragEvent) => {
        preventDefaultBehavior(e);

        dragCounter--;
        if (dragCounter > 0) return;
        setDragging(false);
    };

    const handleDrag = (e: DragEvent) => {
        preventDefaultBehavior(e);
    };

    const handleDrop = (e: DragEvent) => {
        preventDefaultBehavior(e);

        if (e.dataTransfer.files[0].type === 'application/vnd.ms-excel') {
            handleFile(e.dataTransfer.files[0]);
            document.getElementsByClassName('codes-textarea')[0].classList.remove('drop-error');
        } else {
            document.getElementsByClassName('codes-textarea')[0].classList.add('drop-error');
        }

        e.dataTransfer.clearData();
        setDragging(false);
    };

    const handleFile = (file: File) => {
        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = async () => {
            setUploadedCodes(reader.result!);
        };
    };

    return {
        handleDragIn,
        handleDragOut,
        handleDrag,
        handleDrop,
        isDragging,
    };
};

interface UploadAreaProps {
    codes: string;
    setCodes: Function;
    isDragging: boolean;
    onUploadClick: Function;
}

const UploadArea = ({codes, setCodes, isDragging, onUploadClick}: UploadAreaProps) => {
    const {t} = useTranslation();

    return (
        <>
            <textarea
                value={codes}
                className="codes-textarea"
                onChange={(e) => setCodes(e.currentTarget.value)}
                placeholder={t('codes.uploadPlaceholder')}
            />

            {isDragging &&
                <div className="drop-box" id="drop-box">
                    <span>{t('codes.dropHere')}</span>
                </div>
            }

            <button className="btn btn-upload" onClick={() => onUploadClick()}>{t('button.uploadCodes')}</button>
        </>
    );
};

export default UploadArea;
