import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import apiClient from '../store/apiClient';
import {getI18n} from 'react-i18next';
import moment from 'moment';

interface AppSlice {
    merchant?: IMerchant | null;
    error?: string;
    lang: string;
}

const initialState: AppSlice = {
    lang: 'fi',
};

export const DEF_LANGS = [{id: 'fi', name: 'Suomi'}, {id: 'en', name: 'English'}];

export const registerMerchant = createAsyncThunk('merchant/register', async (payload: {authId: string, name: string}): Promise<IMerchant | null> => {
    return await apiClient.registerMerchant(payload.authId, payload.name) || null;
});

export const fetchMerchant = createAsyncThunk('merchant/fetch', async (): Promise<IMerchant | null> => {
    return await apiClient.getMerchant() || null;
});

export const updateLogo = createAsyncThunk('merchant/logo', async (payload: {logo: string, logoBody: string | null | ArrayBuffer}): Promise<IMerchant | null> => {
    return await apiClient.setMerchantLogo(payload.logo, payload.logoBody);
});

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = undefined;
        },
        setError: (state, {payload}: PayloadAction<string>) => {
            state.error = payload;
        },
        setLang: (state, {payload}: PayloadAction<string>) => {
            getI18n().changeLanguage(payload);
            moment.locale(payload);

            state.lang = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(registerMerchant.fulfilled, (state, action) => {
                state.merchant = action.payload;
            })
            .addCase(fetchMerchant.fulfilled, (state, action) => {
                state.merchant = action.payload;
            })
            .addCase(updateLogo.fulfilled, (state, action) => {
                state.merchant = action.payload;
            });
    },
});

export const {clearError, setError, setLang} = appSlice.actions;

export default appSlice.reducer;