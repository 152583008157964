import './ProductTypeSelect.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setProductType} from '../../pages/Product/slice';

const ProductTypeSelect = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const product: IProduct = useAppSelector((state) => state.product.product!);

    const [showOptions, setShowOptions] = useState(false);

    const options: {type: ProductType; label: string}[] = [
        {type: 'coupon', label: t('product.coupon')},
        {type: 'ticket', label: t('product.ticket')},
        {type: 'voucher', label: t('product.voucher')},
    ];

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const handleSelection = (type: ProductType) => {
        setShowOptions(false);
        dispatch(setProductType(type));
    };

    return (
        <>
            <div
                className={'select no-copy-text'}
                onClick={() => toggleOptions()}
                id={'select'}
            >{t(`product.${product.type}`)}</div>

            {showOptions &&
                <div className={'select select-options'}>
                    {options.map(i =>
                        <span
                            key={i.type}
                            className={product.type === i.type ? 'option option_active' : 'option'}
                            onClick={() => handleSelection(i.type)}
                        >{i.label}</span>,
                    )}
                </div>
            }
        </>
    );
};

export default ProductTypeSelect;