import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompanies} from './slice';
import {NavLink, useNavigate} from 'react-router-dom';
import Spinner from '../../../components/Spinner';

const CompaniesPage = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {loading, list} = useAppSelector((state) => state.adminCompanies);

    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);

    return <>
        {loading && <Spinner/>}

        <div style={{padding: '10px 20px'}}>
            <NavLink to={'0'} className="main-link">{t('company.add')}</NavLink>
        </div>

        <table className="table-list">
            <thead>
                <tr>
                    <th>{t('company.name')}</th>
                    <th>{t('company.email')}</th>
                    <th>{t('company.iban')}</th>
                    <th>{t('company.bic')}</th>
                </tr>
            </thead>
            <tbody>
            {list.map((i, k) => <tr key={k} onClick={() => navigate(`${i.id}`)}>
                <td>{i.name}</td>
                <td>{i.email}</td>
                <td>{i.iban}</td>
                <td>{i.bic}</td>
            </tr>)}
            </tbody>
        </table>
    </>;
};

export default CompaniesPage;