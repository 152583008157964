import './VoucherGenerator.css';

import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Editor, EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import Spinner from '../Spinner';
import {DEF_VATS} from '../../pages/Product/slice';
import apiClient from '../../store/apiClient';
import {DEF_COLOR_SCHEMES} from '../../services/utils';

interface ThisComponentProps {
    onCancel: Function;
    onSave: Function;
}

const VoucherGenerator = ({onCancel, onSave}: ThisComponentProps) => {
    const {t} = useTranslation();

    const [nameBase, setNameBase] = useState<string>(t('def.voucher.name'));
    const [startFrom, setStartFrom] = useState<number>(100);
    const [quantity, setQuantity] = useState<number>(100);
    const [colorScheme, setColorScheme] = useState<ColorScheme>(DEF_COLOR_SCHEMES[0]);
    const [taxClass, setTaxClass] = useState<number>(3);
    const [prices, setPrices] = useState<number[]>([5, 10, 15, 20, 25, 30]);
    const [defPrice, setDefPrice] = useState<number>(15);
    const [description, setDescription] = useState<string>(t('def.voucher.desc'));
    const [smsText, setSmsText] = useState<string>(t('def.sms.voucher'));
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(stateFromHTML(description)));
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const preview = useMemo<string[]>(() => {
        const nameBaseParsed = nameBase
            .toLowerCase()
            .replace(/[^a-z]+/g, ' ')
            .trim()
            .replace(/ +/g, '-');

        if (startFrom < 0 || quantity < 1 || quantity > 100) {
            return [];
        }

        const pad = `${startFrom + quantity - 1}`.length;
        const genName = (i: number): string => [nameBaseParsed, `${startFrom + i}`.padStart(pad, '0')].filter(Boolean).join('-');
        // @ts-ignore
        return quantity < 4 ? [...Array(quantity).keys()].map(genName) : [genName(0), genName(1), `...`, genName(quantity - 1)];

    }, [nameBase, startFrom, quantity, colorScheme]);

    const generateVouchers = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (isGenerating) {
            return;
        }

        setIsGenerating(true);

        await apiClient.genVouchers(
            nameBase,
            startFrom,
            quantity,
            colorScheme,
            taxClass,
            prices,
            defPrice,
            description,
            smsText,
        );

        setIsGenerating(false);
        onSave();
    };

    return (
        <div className="modal-overflow">
            {isGenerating
                ? <Spinner/>
                : <form className="modal-voucher" onSubmit={generateVouchers}>
                    <label className="inputLabel">
                        <input
                            type="text"
                            name="nameBase"
                            placeholder={t('voucher.placeholder.name')}
                            value={nameBase}
                            onChange={(e) => setNameBase(e.target.value)}
                        />
                        <span>{t('voucher.name')}</span>
                    </label>

                    <label className="inputLabel">
                        <input
                            required
                            type="number"
                            min="0"
                            step="1"
                            name="startFrom"
                            placeholder={t('voucher.placeholder.from')}
                            value={startFrom}
                            onChange={(e) => setStartFrom(+e.target.value)}
                        />
                        <span>{t('voucher.from')}</span>
                    </label>

                    <label className="inputLabel">
                        <input
                            required
                            type="number"
                            step="1"
                            min="1"
                            max="100"
                            name="quantity"
                            placeholder={t('voucher.placeholder.quantity')}
                            value={quantity}
                            onChange={(e) => setQuantity(+e.target.value)}
                        />
                        <span>{t('voucher.quantity')}</span>
                    </label>

                    <label className="inputLabel">
                        <div className="generatorPreview">
                            {preview.map((i, k) => <div key={k}>{i}</div>)}
                        </div>
                        <span>{t('voucher.preview')}</span>
                    </label>

                    <label className="inputLabel">
                        <p className="vatOptions">
                            {DEF_VATS.filter(Boolean).map(
                                (i, k) => <label key={k} className="cb">
                                    <input type="radio" checked={taxClass === k + 1}
                                           onChange={() => setTaxClass(k + 1)}/>
                                    <span>{i}%</span>
                                </label>,
                            )}
                        </p>
                        <span>{t('voucher.vat')}</span>
                    </label>

                    <div className="inputLabel">
                        <div className="vatOptions">
                            {prices.map(
                                (i, k) => <div key={k} className="inputLabel inputLabelPrice">
                                    <input
                                        type="number"
                                        min={0}
                                        value={i}
                                        step="0.01"
                                        onChange={(e) => {
                                            const tmp = JSON.parse(JSON.stringify(prices));
                                            tmp[k] = +e.target.value;
                                            if (prices[k] === defPrice) {
                                                setDefPrice(+e.target.value);
                                            }
                                            setPrices(tmp);
                                        }}
                                    />
                                    <span>
                                        <label className="cb">
                                            <input type="radio" checked={defPrice === i}
                                                   onChange={() => setDefPrice(i)}/>
                                            <span/>
                                        </label>
                                    </span>
                                </div>,
                            )}
                        </div>
                        <span>{t('voucher.prices')}</span>
                    </div>

                    <label className="inputLabel">
                        <p className="colorOptions">
                            {DEF_COLOR_SCHEMES.map(
                                (i, k) => <span
                                    key={k}
                                    className={colorScheme === i ? 'sel' : ''}
                                    style={{borderColor: `#${i.uiMajorColor}`, backgroundColor: `#${i.uiMinorColor}`}}
                                    onClick={() => setColorScheme(i)}
                                />,
                            )}
                        </p>
                        <span>{t('voucher.colorScheme')}</span>
                    </label>

                    <label className="inputLabel"
                           onBlur={() => setDescription(stateToHTML(editorState.getCurrentContent()))}>
                        <Editor
                            editorState={editorState}
                            onChange={setEditorState}
                        />
                        <span>{t('voucher.desc')}</span>
                    </label>

                    <label className="inputLabel"
                           onBlur={() => setDescription(stateToHTML(editorState.getCurrentContent()))}>
                    <textarea
                        value={smsText}
                        onChange={(e) => setSmsText(e.target.value)}
                    />
                        <span>{t('voucher.sms')}</span>
                    </label>

                    <div className="buttons">
                        <button className="btn btn-lg btn-outline-danger" type="button"
                            onClick={() => onCancel()}>{t('button.close')}</button>
                        <button className="btn btn-lg btn-outline-primary" type="submit">
                            {t('button.generate.')}</button>
                    </div>
                </form>
            }
        </div>
    );
};

export default VoucherGenerator;

