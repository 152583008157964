import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import './i18nConfig'; // init `i18next` so that it could be used later in the app
import {Auth0Provider} from '@auth0/auth0-react';
import {Provider as ReduxProvider} from 'react-redux';
import store from './redux/store';

ReactDOM.render((
    <ReduxProvider store={store}>
        <Auth0Provider
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            audience={config.auth0.audience}
            redirectUri={window.location.origin}
        >
            <App/>
        </Auth0Provider>
    </ReduxProvider>
), document.getElementById('root'));
