import './ProductCard.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import {initDownload} from '../../services/imageService';
import {NavLink} from 'react-router-dom';

interface ProductCardProps {
    merchantUrl: string,
    product: IProduct;
    genQrCode: Function;
    getVoucherPdf: Function;
}

const openQrCode = (name: string, svgQrCode: string) => {
    const blob = new Blob([svgQrCode], {type: 'image/svg+xml'});

    initDownload(name, URL.createObjectURL(blob));
};

const ProductCard = ({merchantUrl, product, genQrCode, getVoucherPdf}: ProductCardProps) => {
    const {t} = useTranslation();

    const {name, description, image, id, isHidden, isPublished, totalPrice, type} = product;

    let productTypeClasses = 'product-type';

    if (isPublished) {
        productTypeClasses += ' product-type_published';
    }

    if (isHidden) {
        productTypeClasses += ' product-type_hidden';
    }

    const createDescriptionMarkup = () => {
        return {__html: description};
    };

    return (
        <section className="product-card" id={`${id}`}>
            <div className={productTypeClasses}/>
            <div className="product-info">
                <div>
                    <h3 className="product-title">{name}</h3>
                    <a target="_blank" className="shop-link"
                       href={`${merchantUrl}/${product.urlName}`}>{merchantUrl}/{product.urlName}</a>
                </div>

                <span className="product-price">{totalPrice} &euro;</span>

                <div className="product-description" dangerouslySetInnerHTML={createDescriptionMarkup()}/>

                <div className="product-btns">
                    <NavLink className="product-btn product-edit" to={`/product/${product.id}`}>{t('button.edit')}</NavLink>

                    {type === 'coupon' && <NavLink className="product-btn product-codes" to={`/codes/${product.id}`}>{t('button.codes')}</NavLink>}

                    {!product.qrCode && <button className="product-btn product-edit" onClick={() => genQrCode(id)}>{t('button.genQr')}</button>}

                    {product.qrCode && <button className="product-btn product-edit" onClick={() => openQrCode(product.urlName, product.qrCode!)}>{t('button.getQr')}</button>}

                    {product.type === 'voucher' && <button className="product-btn product-edit" onClick={() => getVoucherPdf(product.id!, product.urlName)}>{t('button.getVoucher')}</button>}
                </div>
            </div>

            <div className="product-img" style={{background: `url('${image}') 0 0 / 100% no-repeat`}}/>
        </section>
    );
};

export default ProductCard;