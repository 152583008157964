import i18n from '../../i18nConfig';
import config from '../../config';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getImageBody} from '../../services/imageService';
import apiClient from '../../store/apiClient';
import {priceCeil, priceFloor, priceRound} from '../../services/price';
import { hexToRgba } from '../../services/utils';

export const DEF_PROD_PRICE = 9.09;
export const DEF_PROD_TOTAL_PRICE = 10;
export const DEF_PROD_SERVICE_FEE = 0.91;
export const DEF_PROD_BASE_PRICE = 8.06;
export const DEF_PROD_TAX_VALUE = 1.94;
export const DEF_IMAGE = `/images/default-product.jpg`;

export const compareColorSchemes = (s1: ColorScheme, s2: ColorScheme) => s1.uiMajorColor === s2.uiMajorColor
    && s1.uiMinorColor === s2.uiMinorColor
    && s1.uiMajorTextColor === s2.uiMajorTextColor
    && s1.uiMinorTextColor === s2.uiMinorTextColor;

export const DEF_VATS: TaxValue[] = [0, 10, 14, 24];

export const formatToStringView = (price: number): string => {
    let newPrice = (+price).toFixed(2).replace('.', ',');
    return newPrice.slice(-2) === '00' ? newPrice.slice(0, -3) : newPrice;
};

export const setIconUrlVariables = () => {
    document.documentElement.style.setProperty('--i-share-url', `url(${config.site.url}/i/share.png)`);
    document.documentElement.style.setProperty('--i-receipt-url', `url(${config.site.url}/i/receipt.png)`);
    document.documentElement.style.setProperty('--i-use-url', `url(${config.site.url}/i/use.png)`);
    document.documentElement.style.setProperty('--i-copy-url', `url(${config.site.url}/i/copy.png)`);
    document.documentElement.style.setProperty('--i-link-url', `url(${config.site.url}/i/link.png)`);
    document.documentElement.style.setProperty('--i-exit-url', `url(${config.site.url}/i/exit.png)`);
    document.documentElement.style.setProperty('--i-capture-url', `url(${config.site.url}/i/capture.png)`);
    document.documentElement.style.setProperty('--i-popup_close-url', `url(${config.site.url}/i/popup_close.png)`);
    document.documentElement.style.setProperty('--i-wifi-url', `url(${config.site.url}/i/wifi.png)`);
    document.documentElement.style.setProperty('--i-fail-url', `url(${config.site.url}/i/fail.png)`);
    document.documentElement.style.setProperty('--i-logo-mm-url', `url(${config.site.url}/i/mobiilimaksu-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-elisa-url', `url(${config.site.url}/i/elisa-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-telia-url', `url(${config.site.url}/i/telia-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-moi-url', `url(${config.site.url}/i/moi-logo.png`);
    document.documentElement.style.setProperty('--i-logo-dna-url', `url(${config.site.url}/i/dna-logo.png`);
};

export const genDefProduct = async (merchant: IMerchant): Promise<IProduct> => {
    const imageBody = await getImageBody(DEF_IMAGE);

    return {
        type: 'coupon',
        id: 0,
        merchantId: merchant.id,
        companyId: null,
        name: i18n.t('def.prod.name'),
        urlName: '',
        description: i18n.t('def.prod.description'),
        short: '',
        image: DEF_IMAGE,
        deliveryUrl: '',

        productPrice: DEF_PROD_PRICE,
        totalPrice: DEF_PROD_TOTAL_PRICE,
        serviceFee: DEF_PROD_SERVICE_FEE,
        basePrice: DEF_PROD_BASE_PRICE,
        taxValue: DEF_PROD_TAX_VALUE,
        taxClass: 3,

        isHidden: 0,
        isPublished: 1,
        showServiceFee: 1,

        uiMajorColor: merchant.uiMajorColor,
        uiMinorColor: merchant.uiMinorColor,
        uiMajorTextColor: merchant.uiMajorTextColor,
        uiMinorTextColor: merchant.uiMinorTextColor,

        tpSuccessTitle: i18n.t('def.tp.success.title'),
        tpSuccessText: i18n.t('def.tp.success.text'),
        tpSuccessInstructionsText: i18n.t('def.tp.success.instructions.text'),
        tpSuccessInstructionsButton: i18n.t('def.tp.success.instructions.button'),
        tpShowShare: 1,
        tpShowReceipt: 1,
        tpShowInstructions: 1,

        smsText: i18n.t('def.sms.coupon'),
        prices: null,
        qrCode: null,
        redirectUrl: null,
        phoneNumber: null,
        imageBody: imageBody,
    };
};

interface SliceType {
    product?: IProduct;
    productBackup?: string;
}

interface SetPrices {
    price: number;
    taxClass: number;
    prices: string[];
    defPrice: number;
    serviceFee: number;
    sirupayFee: number;
}

const initialState: SliceType = {};

export const setEditProduct = createAsyncThunk('product/set', async (payload: {id: string | undefined, merchant: IMerchant}): Promise<IProduct> => {
    const product = payload.id && payload.id !== '0' ? await apiClient.getProduct(+payload.id) : await genDefProduct(payload.merchant);

    product.uiMinorColorLightRGBA = hexToRgba(product.uiMinorColor, .8);

    return product;
});

const slice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        resetEditProduct: (state) => {
            if (state.productBackup) {
                state.product = JSON.parse(state.productBackup);
            }
        },
        setProductType: (state, {payload}: PayloadAction<ProductType>) => {
            if (!state.product) {
                return;
            }

            state.product.type = payload;
            state.product.smsText = i18n.t(`def.sms.${payload}`);

            state.product.prices = payload === 'voucher' ? ['5', '10', '15', '20', '25', '30'].join('|') : null;
        },
        setProductImage: (state, {payload}: PayloadAction<{image: string; imageBody: any}>) => {
            if (!state.product) {
                return;
            }

            state.product.image = payload.image;
            state.product.imageBody = payload.imageBody;
        },
        setProductPrices: (state, {payload}: PayloadAction<SetPrices>) => {
            if (!state.product) {
                return;
            }

            state.product.taxClass = payload.taxClass;

            const taxPercent = DEF_VATS[state.product.taxClass] / 100;

            if (state.product.type === 'voucher') {
                state.product.prices = payload.prices.filter(Boolean).join('|');
                state.product.productPrice = payload.defPrice;
                state.product.totalPrice = priceCeil(state.product.productPrice * (1 + payload.sirupayFee));
            } else {
                state.product.prices = null;
                state.product.totalPrice = priceRound(payload.price);
                state.product.productPrice = priceRound(state.product.totalPrice / (1 + payload.serviceFee));
            }

            state.product.serviceFee = priceRound(state.product.totalPrice - state.product.productPrice);
            state.product.basePrice = priceFloor(state.product.totalPrice / (1 + taxPercent));
            state.product.taxValue = priceRound(state.product.totalPrice - state.product.basePrice);
        },
        toggleProductFlag: (state, {payload}: PayloadAction<string>) => {
            if (!state.product) {
                return;
            }

            if (state.product[payload] === 1) {
                state.product[payload] = 0;
            } else if (state.product[payload] === 0) {
                state.product[payload] = 1;
            }
        },
        setProductString: (state, {payload}: PayloadAction<Record<string, any>>) => {
            if (!state.product) {
                return;
            }

            for (const [key, value] of Object.entries(payload)) {
                state.product[key] = value;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(setEditProduct.fulfilled, (state, action) => {
                state.product = action.payload;
                state.productBackup = JSON.stringify(action.payload);
            })
    },
});

export const {resetEditProduct, setProductType, setProductPrices, setProductImage, toggleProductFlag, setProductString} = slice.actions;

export default slice.reducer;