export const DEF_COLOR_SCHEMES: ColorScheme[] = [
    {uiMajorColor: 'ffffff', uiMinorColor: 'b1f2ff', uiMajorTextColor: '494e4f', uiMinorTextColor: '494e4f'},
    {uiMajorColor: 'ffffff', uiMinorColor: '3cdfff', uiMajorTextColor: '1f2024', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'd8f9ff', uiMinorColor: '475559', uiMajorTextColor: '475559', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: '3cdfff', uiMinorColor: 'ffffff', uiMajorTextColor: 'ffffff', uiMinorTextColor: '1f2024'},
    {uiMajorColor: '373c58', uiMinorColor: '00c2e8', uiMajorTextColor: 'ffffff', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'ffffff', uiMinorColor: 'feb904', uiMajorTextColor: '0c0c0c', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'ffffff', uiMinorColor: '00c2e8', uiMajorTextColor: '0c0c0c', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'ffffff', uiMinorColor: 'ff5fb4', uiMajorTextColor: '373c58', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: '170525', uiMinorColor: '9F2B93', uiMajorTextColor: 'FFFFFF', uiMinorTextColor: 'FFFFFF'},
    {uiMajorColor: '1f2024', uiMinorColor: '3cdfff', uiMajorTextColor: 'ffffff', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'f4f4f4', uiMinorColor: '32854c', uiMajorTextColor: '0c0c0c', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'f2f2f2', uiMinorColor: '827676', uiMajorTextColor: '9a021f', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: 'faf0ef', uiMinorColor: '5e556a', uiMajorTextColor: '5e556a', uiMinorTextColor: 'faf0ef'},
    {uiMajorColor: '2f4058', uiMinorColor: 'feb904', uiMajorTextColor: 'ffffff', uiMinorTextColor: 'ffffff'},
    {uiMajorColor: '2f4058', uiMinorColor: '4ab76c', uiMajorTextColor: 'ffffff', uiMinorTextColor: 'ffffff'},
];

export const hexToRgba = (hex, opacity=1) => {
    const {style} = document.createElement('div');
    style.color = `#${hex}`;
    return [
        ...style.color.replace(/^rgb\((\d+,\s?\d+,\s?\d+)\)$/, '$1').split(',').map(i => +i.trim() + 10),
        opacity
    ].join(', ');
};