import './ProductEditModals.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {DEF_VATS, setProductPrices, toggleProductFlag} from '../../pages/Product/slice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';

interface PriceProps {
    closeModal(): void;
}

export const genDefPrices = (prices?: string) => {
    if (!prices) {
        return ['5', '10', '15', '20', '25', '30'];
    }

    const tmp = (prices || '').split('|').map((i) => i.trim()).filter(Boolean);

    while (tmp.length < 6) {
        tmp.push('');
    }

    return tmp;
};

const preparePriceValue = (value) => {
    value = value.replace(',', '.'); // replace all dots by commas
    value = value.replace(/[^0-9.]/g, ''); // strip all characters except numbers and commas
    value = value.replace(/\.+/, '.');
    const matches = value.match(/\d+\.\d+/g); // find all matches for decimal numbers

    return matches ? matches[0] : value;
};

const PriceModal = ({closeModal}: PriceProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const product: IProduct = useAppSelector((state) => state.product.product!);
    const merchant: IMerchant = useAppSelector((state) => state.app.merchant!);

    const [taxClass, setTaxClass] = useState(product.taxClass);
    const [price, setPrice] = useState(product.totalPrice);
    const [prices, setPrices] = useState(genDefPrices(product.prices || ''));
    const [defPrice, setDefPrice] = useState(+product.productPrice);

    const onSave = () => {
        dispatch(setProductPrices({
            defPrice,
            price,
            prices,
            taxClass,
            serviceFee: +merchant.serviceFee,
            sirupayFee: +merchant.sirupayFee
        }));
        closeModal();
    };

    const vatSwitchers = DEF_VATS.filter(i => i !== 0).map((tax, i) => {
        return (
            <label key={i} className="vat-switch">
                {tax}%
                <input
                    type="radio"
                    name="vat-rates"
                    value={i + 1}
                    checked={taxClass === i + 1}
                    onChange={() => setTaxClass(i + 1)}
                />
                <span className="vat-switch-name"/>
            </label>
        );
    });

    return (
        <div className="price-modal">
            {product.type === 'voucher' && <div>
                {prices.map((i, k) => <div key={k} style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    gap: '10px',
                }}>
                    <label className="price-modal-label">
                        {t('priceModal.price')}
                        <input
                            type="text"
                            value={i}
                            onChange={(e) => {
                                const newPrices = JSON.parse(JSON.stringify(prices));
                                newPrices[k] = preparePriceValue(e.target.value);
                                setPrices(newPrices);
                            }}
                            className="price-input"
                        />
                        &euro;
                    </label>
                    <label className="cb">
                        <input
                            type="radio"
                            value={i}
                            checked={defPrice === +i}
                            onChange={() => setDefPrice(+i)}
                        />
                        <span/>
                    </label>
                </div>)}
            </div>}

            {product.type !== 'voucher' && <label className="price-modal-label">
                {t('priceModal.price')}
                <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(preparePriceValue(e.target.value))}
                    className="price-input"
                />
                &euro;
            </label>}

            <div>
                <p>{t('priceModal.vatTitle')}</p>
                <div className="price-modal-vats">
                    {vatSwitchers}
                </div>
            </div>

            <div>
                <div className="price-modal-check-fee">
                    <span>{t('priceModal.text')}</span>

                    <label className="cb big toggle">
                        <input
                            type="checkbox"
                            checked={!!product.showServiceFee}
                            onChange={() => dispatch(toggleProductFlag('showServiceFee'))}
                        />
                        <span/>
                    </label>
                </div>

                <div className="price-modal-fee-text">{t('product.priceIncludesFee')}</div>
            </div>

            <div className="d-flex justify-content-between">
                <button className="btn btn-success price-modal-btn" onClick={onSave}>{t('button.save')}</button>

                <button className="btn btn-dark price-modal-btn" onClick={closeModal}>{t('button.close')}</button>
            </div>
        </div>
    );
};

export default PriceModal;
