import moment from 'moment';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import Spinner from '../../../components/Spinner';
import {fetchTransfers} from './slice';
import {format} from '../../../services/price';
import apiClient from '../../../store/apiClient';
import Link from '../../../components/Link';

const TransfersPage = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const {loading, list} = useAppSelector((state) => state.adminTransfers);

    useEffect(() => {
        dispatch(fetchTransfers());
    }, []);

    const getTransferFile = async (id: number, name: string) => {
        try {
            await apiClient.getTransferFile(id, name);
        } catch (e) {

        }
    };

    const getMarginsReportFile = async (id: number, name: string) => {
        try {
            await apiClient.getMarginsReportFile(id, name);
        } catch (e) {}
    };

    return <>
        {loading && <Spinner/>}

        <div style={{padding: '10px 20px'}}>
            {/*<a>Generate transfer</a>*/}
        </div>

        <table className="table-list no-active">
            <thead>
            <tr>
                <th>{t('transfer.date')}</th>
                <th>{t('transfer.id')}</th>
                <th className="align-c">{t('transfer.recipients')}</th>
                <th className="align-r">{t('transfer.total')}</th>
                <th className="align-c">{t('transfer.margins')}</th>
                <th className="align-r min-width">{t('transfer.transactions')}</th>
            </tr>
            </thead>
            <tbody>
            {list.map((i, k) => <tr key={k}>
                <td>{moment(i.dateCreated).format('DD MMM YYYY')}</td>
                <td>
                    <div className="flex ai-c gap-10">
                        <Link icon={'download-xml'} onClick={() => getTransferFile(i.id, i.transferId)} />
                        {i.transferId}
                    </div>
                </td>
                <td className="align-c">{i.recipients}</td>
                <td className="align-r">{format(i.total)} €</td>
                <td>
                    <div className="flex jc-c">
                        <Link icon={'download-pdf'} onClick={() => getMarginsReportFile(i.id, i.transferId)} />
                    </div>
                </td>
                <td>
                    <div className="flex jc-e ai-c gap-10">
                        {i.orders}
                        <Link href={`/admin/transactions?transfer=${i.transferId}`} icon={'ext-link'} inNewTab={true} />
                    </div>
                </td>
            </tr>)}
            </tbody>
        </table>
    </>;
};

export default TransfersPage;