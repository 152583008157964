import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ContentState, convertFromHTML, Editor, EditorState} from 'draft-js';
import {useAppSelector} from '../../../../redux/hooks';
import Receipt from '../../../../components/Receipt';

const TicketPage = () => {
    const {t} = useTranslation();

    const merchant = useAppSelector((state) => state.app.merchant!);
    const product: IProduct = useAppSelector((state) => state.product.product!);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    // watch for changes in product description and set new Editor state
    useEffect(() => {
        const HTMLDescription = convertFromHTML(product.description);
        const editedTextState = ContentState.createFromBlockArray(
            HTMLDescription.contentBlocks,
            HTMLDescription.entityMap,
        );
        setEditorState(() => EditorState.createWithContent(editedTextState));
    }, [product.description]);

    return (
        <div className="lift-body">
            <div className="sm-lift">
                <div className="sm-floor sm-floor-flex" id="ticket">
                    <div className="sm-order-head">
                        <div className="sm-order-logo" style={{backgroundImage: `url(${merchant.logo})`}}/>
                    </div>

                    <div className="sm-order-ticket">
                        <div className="sm-order-ticket-part">
                            <div className="sm-order-ticket-inner">
                                <div className="sm-order-ticket-bg"
                                     style={{backgroundImage: `url(${product.image})`}}/>
                                <div className="sm-order-ticket-content">
                                    <div className="sm-order-ticket-datetime">
                                        <span>4da82bed-7f4a-11ea-8f46-000d3ab9eb7e</span>
                                        <span>24.4.2024 10:07</span>
                                    </div>

                                    <div className="sm-order-ticket-title">{t('ticket.title')}</div>

                                    <div className="sm-order-ticket-product">
                                        <span>{product.name}</span>
                                        <b>{product.totalPrice}&euro;</b>
                                    </div>

                                    <div className="sm-order-ticket-datetime">
                                        <span>4da82bed-7f4a-11ea-8f46-000d3ab9eb7e</span>
                                        <span>24.4.2024 10:07</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sm-order-ticket-separator"></div>

                        <div className="sm-order-ticket-part sm-order-ticket-part-control">
                            <div className="sm-order-ticket-inner">
                                <img className="sm-order-ticket-qr"
                                     src="https://qrcoder.ru/code/?4da82bed-7f4a-11ea-8f46-000d3ab9eb7e&4&0"
                                     title="QR code" alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className="sm-next-wrap">
                        <a className="sm-next" href="#description"/>
                    </div>
                </div>

                <div className="sm-floor" id="description">
                    <div className="sm-product-panel">
                        <div className="sm-product-description">
                            <Editor editorState={editorState}/>
                        </div>

                        <div>
                            <a className="sm-next" href="#receipt"/>
                        </div>
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="receipt">
                    <Receipt product={product} merchant={merchant}/>

                    <div>
                        <a className="sm-next" href="#blank"/>
                    </div>
                </div>

                <div className="sm-floor sm-blank-floor" id="blank">
                    <div className="sm-product-panel"/>
                </div>

                <div className="sm-floor sm-floor-caution" id="caution">
                    <div className="sm-product-panel">
                        <div className="sm-caution-title"><b>{t('ticket.for')}</b> {t('ticket.staffOnly')}</div>

                        <div className="sm-caution-text">{t('ticket.deactivationSection')}</div>

                        <span className="sm-deactivator">
                            <span className="sm-deactivator-text">{t('ticket.deactivate')}</span>
                            <span className="sm-deactivator-slider" id="deactivationButton"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketPage;