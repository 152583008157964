import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from '../../../i18nConfig';
import apiClient from '../../../store/apiClient';
import {
    DEF_IMAGE,
    DEF_PROD_BASE_PRICE,
    DEF_PROD_PRICE,
    DEF_PROD_SERVICE_FEE,
    DEF_PROD_TAX_VALUE,
    DEF_PROD_TOTAL_PRICE
} from '../../Product/slice';

export const genDefProduct = (): IProduct => ({
    type: 'coupon',
    id: 0,
    merchantId: 0,
    companyId: null,
    name: i18n.t('def.prod.name'),
    urlName: '',
    description: i18n.t('def.prod.description'),
    short: '',
    image: DEF_IMAGE,
    deliveryUrl: '',

    productPrice: DEF_PROD_PRICE,
    totalPrice: DEF_PROD_TOTAL_PRICE,
    serviceFee: DEF_PROD_SERVICE_FEE,
    basePrice: DEF_PROD_BASE_PRICE,
    taxValue: DEF_PROD_TAX_VALUE,
    taxClass: 3,

    isHidden: 0,
    isPublished: 1,
    showServiceFee: 1,

    uiMajorColor: 'ffffff',
    uiMinorColor: 'b1f2ff',
    uiMajorTextColor: '494e4f',
    uiMinorTextColor: '494e4f',

    tpSuccessTitle: i18n.t('def.tp.success.title'),
    tpSuccessText: i18n.t('def.tp.success.text'),
    tpSuccessInstructionsText: i18n.t('def.tp.success.instructions.text'),
    tpSuccessInstructionsButton: i18n.t('def.tp.success.instructions.button'),
    tpShowShare: 1,
    tpShowReceipt: 1,
    tpShowInstructions: 1,

    smsText: i18n.t('def.sms.coupon'),
    prices: null,
    qrCode: null,
    redirectUrl: null,
    phoneNumber: null,
    imageBody: null,
});

interface SliceType {
    loading: boolean,
    data?: IProduct;
    companies: ICompany[];
}

const initialState: SliceType = {
    loading: false,
    data: undefined,
    companies: [],
};

export const fetchProduct = createAsyncThunk('product/fetch', async (id: number): Promise<IProduct> => {
    return id === 0 ? genDefProduct() : await apiClient.getProduct(id);
});

export const fetchCompanies = createAsyncThunk('companies/fetch', async (): Promise<ICompany[]> => {
    return await apiClient.getCompanies();
});

const slice = createSlice({
    name: 'adminProductSlice',
    initialState,
    reducers: {
        resetProductEditData: (state) => {
            state.data = undefined;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProduct.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchProduct.rejected, (state) => {
                state.loading = false;
            })

            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.companies = action.payload;
            })
            .addCase(fetchCompanies.rejected, (state) => {
                state.companies = [];
            })
    },
});

export const {resetProductEditData} = slice.actions;

export default slice.reducer;