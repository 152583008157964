import './styles.css';

import React from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

import Receipt from '../../../../components/Receipt';
import HTMLAndTextEditor from '../../../../components/HTMLAndTextEditor';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {setProductString, toggleProductFlag} from '../../slice';

const Coupon = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const merchant = useAppSelector((state) => state.app.merchant!);
    const product: IProduct = useAppSelector((state) => state.product.product!);

    return (
        <div className="sm-lift">
            <div className="sm-floor sm-floor-flex" id="landing">
                <div className="sm-order-head">
                    <div className="sm-order-logo" style={{backgroundImage: `url(${merchant.logo})`}}/>
                </div>

                <div>
                    <TextareaAutosize
                        defaultValue={product.tpSuccessTitle}
                        onChange={(e) => dispatch(setProductString({tpSuccessTitle: e.currentTarget.value}))}
                        className="sm-order-title sm-product-title_editable sm-product-text_editable editable"
                    />

                    <TextareaAutosize
                        defaultValue={product.tpSuccessText}
                        onChange={(e) => dispatch(setProductString({tpSuccessText: e.currentTarget.value}))}
                        className="sm-order-text sm-product-text_editable sm-order-text_editable editable"
                    />
                </div>

                <div>
                    <a className="sm-button" href="#code">
                        <span className="sm-next"/> {t('button.continue')}
                    </a>
                </div>
            </div>

            <div className="sm-floor sm-floor-flex" id="code">
                <div>
                    <h2 className="sm-order-subtitle">{t('coupon.copyCode')}</h2>

                    <div className="sm-order-text">{t('coupon.byButton')}</div>
                </div>

                <div className="sm-order-inner">
                    <div className="sm-order-code">{t('coupon.code')}</div>

                    <div className="sm-order-buttons">
                        <button type="button" id="copy_btn"
                                className="sm-button sm-button-action sm-button-copy">{t('button.copy')}
                        </button>
                    </div>
                </div>

                <div>
                    <div className="sm-next-text">{t('coupon.proceed')}</div>
                    <a className="sm-next" href="#next"/>
                </div>
            </div>

            <div className="sm-floor sm-floor-flex" id="next">
                <div>
                    <h2 className="sm-order-subtitle">{t('coupon.whatNext')}</h2>

                    <div className="sm-order-text">{t('coupon.chooseFromIcons')}</div>
                </div>

                <div className="sm-order-inner">
                    <div className="option-switcher editable">
                        <a className="sm-order-option sm-order-option-share" href="#share">
                            <span>{t('coupon.shareWithFriend')}</span>
                        </a>

                        <label className="cb big toggle">
                            <input
                                type="checkbox"
                                checked={!!product.tpShowShare}
                                onChange={() => dispatch(toggleProductFlag('tpShowShare'))}
                            />
                            <span/>
                        </label>
                    </div>

                    <div className="option-switcher editable">
                        <a className="sm-order-option sm-order-option-white sm-order-option-receipt" href="#receipt">
                            <span>{t('coupon.receipt')}</span>
                        </a>

                        <label className="cb big toggle">
                            <input
                                type="checkbox"
                                checked={!!product.tpShowReceipt}
                                onChange={() => dispatch(toggleProductFlag('tpShowReceipt'))}
                            />
                            <span/>
                        </label>
                    </div>

                    <div className="option-switcher editable">
                        <a className="sm-order-option sm-order-option-use" href="#use">
                            <span>{t('coupon.useCode')}</span>
                        </a>

                        <label className="cb big toggle">
                            <input
                                type="checkbox"
                                checked={!!product.tpShowInstructions}
                                onChange={() => dispatch(toggleProductFlag('tpShowInstructions'))}
                            />
                            <span/>
                        </label>
                    </div>
                </div>

                <div>
                    <a className="sm-next" href="#share"/>
                </div>
            </div>

            <div className="sm-floor sm-floor-flex" id="share">
                <div>
                    <h2 className="sm-order-subtitle">{t('coupon.shareWithFriendQ')}</h2>

                    <div className="sm-order-text">{t('coupon.delightFriend')}</div>
                </div>

                <div className="sm-order-inner">
                    <div className="sm-order-share-text">{t('coupon.onceUse')}</div>

                    <div className="sm-order-buttons">
                        <button disabled type="button" id="share_btn"
                                className="sm-button sm-button-action sm-button-share">{t('button.share')}
                        </button>
                    </div>
                </div>

                <div>
                    <a className="sm-next" href="#receipt"/>
                </div>
            </div>

            <div className="sm-floor sm-floor-flex" id="receipt">
                <Receipt product={product} merchant={merchant}/>

                <div>
                    <a className="sm-next" href="#use"/>
                </div>
            </div>

            <div className="sm-floor sm-floor-flex" id="use">
                <div>
                    <h2 className="sm-order-title">{t('coupon.goToView')}</h2>
                </div>

                <div className="sm-order-text sm-order-inner">
                    <HTMLAndTextEditor editedProductField={'tpSuccessInstructionsText'}/>
                </div>

                <div className="sm-button-wrap">
                    <TextareaAutosize
                        defaultValue={product.tpSuccessInstructionsButton}
                        onChange={(e) => dispatch(setProductString({tpSuccessInstructionsButton: e.currentTarget.value}))}
                        className="sm-button sm-button-action sm-button-use button_editable editable"
                        style={{width: '250px'}}
                    />
                </div>
            </div>
        </div>
    );
};

export default Coupon;