import './styles.css';

import React from 'react';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CompaniesPage from './CompaniesPage';
import CompanyPage from './CompanyPage';
import ProductsPage from './ProductsPage';
import ProductPage from './ProductPage';
import TransfersPage from './TransfersPage';
import ReportsPage from './ReportsPage';
import TransactionsPage from './TransactionsPage';

const AdminPage = () => {
    const {t} = useTranslation();

    return <>
        <div className="admin-panel">
            <div className="admin-panel-nav">
                <NavLink to="transactions">{t('admin.transactions')}</NavLink>

                <NavLink to="product">{t('admin.products')}</NavLink>

                <NavLink to="company">{t('admin.paymentAccounts')}</NavLink>

                <NavLink to="transfer">{t('admin.transfers')}</NavLink>

                <NavLink to="report">{t('admin.reports')}</NavLink>
            </div>

            <Routes>
                <Route path="transactions" element={<TransactionsPage/>}/>

                <Route path="product/*">
                    <Route index element={<ProductsPage/>}/>
                    <Route path=":id" element={<ProductPage/>}/>
                </Route>

                <Route path="company/*">
                    <Route index element={<CompaniesPage/>}/>
                    <Route path=":id" element={<CompanyPage/>}/>
                </Route>

                <Route path="transfer" element={<TransfersPage/>}/>

                <Route path="report" element={<ReportsPage/>}/>

                <Route path="" element={<Navigate to="transactions" replace/>}/>
            </Routes>
        </div>
    </>;
};

export default AdminPage;