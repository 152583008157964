import './styles.css';

import React from 'react';
import {useTranslation} from 'react-i18next';

import Coupon from './Coupon';
import Ticket from './Ticket';
import Voucher from './Voucher';
import {NavLink} from 'react-router-dom';
import {useAppSelector} from '../../../redux/hooks';

const ThankPage = () => {
    const {t} = useTranslation();
    const product: IProduct = useAppSelector((state) => state.product.product!);

    return (
        <div className="lift-body">
            <div className="fix-btn-wrap">
                <NavLink className="main-button cancel-button" to="../type">{t('button.back')}</NavLink>
            </div>

            <div className="fix-btn-wrap fix-btn-wrap_right">
                <NavLink className="main-button" to="../settings">{t('button.next')}</NavLink>
            </div>

            {product.type === 'coupon' && <Coupon/>}

            {product.type === 'ticket' && <Ticket/>}

            {product.type === 'voucher' && <Voucher/>}
        </div>
    );
};

export default ThankPage;