import './ProductEditModals.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {setProductString} from '../../pages/Product/slice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import { DEF_COLOR_SCHEMES, hexToRgba } from '../../services/utils';

const validateColor = (color) => /^#[0-9A-F]{6}$/i.test(color);

interface ColorSchemeProps {
    closeModal(): void;
}

const ColorModal = ({closeModal}: ColorSchemeProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const product: IProduct = useAppSelector((state) => state.product.product!);

    const [uiMajorColor, setUiMajorColor] = useState<string>(product.uiMajorColor);
    const [uiMinorColor, setUiMinorColor] = useState<string>(product.uiMinorColor);
    const [uiMinorColorLight, setUiMinorColorLight] = useState<string>(hexToRgba(product.uiMinorColor, .8));
    const [uiMajorTextColor, setUiMajorTextColor] = useState<string>(product.uiMajorTextColor);
    const [uiMinorTextColor, setUiMinorTextColor] = useState<string>(product.uiMinorTextColor);

    const colorSchemeList = DEF_COLOR_SCHEMES.map((colorScheme, i) => {
        return (
            <div
                key={i}
                onClick={() => {
                    setUiMajorColor(colorScheme.uiMajorColor);
                    setUiMinorColor(colorScheme.uiMinorColor);
                    setUiMinorColorLight(hexToRgba(colorScheme.uiMinorColor, .8));
                    setUiMajorTextColor(colorScheme.uiMajorTextColor);
                    setUiMinorTextColor(colorScheme.uiMinorTextColor);
                }}
                className="color-scheme"
                style={{
                    width: '40px',
                    height: '40px',
                    background: `#${colorScheme.uiMajorColor}`,
                    marginBottom: '10px',
                    padding: '12px',
                }}
            >
                <div style={{background: `#${colorScheme.uiMinorColor}`, width: '100%', height: '100%'}}/>
            </div>
        );
    });

    return (
        <div className="color-picker">
            <style type="text/css">
                :root {`{
                    --major-color: #${uiMajorColor};
                    --minor-color: #${uiMinorColor};
                    --minor-color--light: rgba(${uiMinorColorLight});
                    --major-text-color: #${uiMajorTextColor};
                    --minor-text-color: #${uiMinorTextColor};
                }`}
            </style>
            <h4 className="color-picker-title">{t('colorModal.chooseColors')}</h4>
            <div className="color-picker-schemes">
                {colorSchemeList}
            </div>

            <h4 className="color-picker-title">{t('colorModal.inputColors')}</h4>
            <div className="color-picker-inputs">
                <label className="color-picker-label">
                    {t('colorModal.majorColor')}
                    <input value={uiMajorColor} onChange={(e) => setUiMajorColor(e.target.value)}/>
                </label>
                <label className="color-picker-label">
                    {t('colorModal.minorColor')}
                    <input value={uiMinorColor} onChange={(e) => setUiMinorColor(e.target.value)}/>
                </label>
                <label className="color-picker-label">
                    {t('colorModal.majorTextColor')}
                    <input value={uiMajorTextColor} onChange={(e) => setUiMajorTextColor(e.target.value)}/>
                </label>
                <label className="color-picker-label">
                    {t('colorModal.minorTextColor')}
                    <input value={uiMinorTextColor} onChange={(e) => setUiMinorTextColor(e.target.value)}/>
                </label>
            </div>

            <div className="color-picker-buttons">
                <button
                    className="btn btn-success modal-btn"
                    onClick={() => {
                        dispatch(setProductString({
                            uiMajorColor: uiMajorColor.replace('#', ''),
                            uiMinorColor: uiMinorColor.replace('#', ''),
                            uiMinorColorLightRGBA: uiMinorColorLight,
                            uiMajorTextColor: uiMajorTextColor.replace('#', ''),
                            uiMinorTextColor: uiMinorTextColor.replace('#', ''),
                        }));
                        closeModal();
                    }}
                >{t('button.save')}</button>
                <button
                    className="btn btn-dark modal-btn"
                    onClick={closeModal}
                >{t('button.close')}</button>
            </div>
        </div>
    );
};

export default ColorModal;
