import './styles.css';

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';
import {NavLink, useNavigate} from 'react-router-dom';

import Spinner from '../../../components/Spinner';
import SmsTextEditor from '../../../components/SmsTextEditor/SmsTextEditor';
import CommonInput from '../../../components/CommonInput';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {setProductString, toggleProductFlag} from '../slice';
import apiClient from '../../../store/apiClient';

const SettingsPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [merchant, product] = useAppSelector((state) => [state.app.merchant!, state.product.product!]);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const saveProduct = async () => {
        if (product.redirectUrl) {
            try {
                const url = new URL(product.redirectUrl);

                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                    throw new Error();
                }
            } catch (e) {
                const el = document.getElementById('redirectUrl');

                if (el) {
                    el.focus();
                    el.className = `${el.className} sms-page-input-invalid`;
                }

                return false;
            }
        }

        setIsDataLoading(true);

        const commonFields = [
            product.type,
            product.name,
            product.description,
            product.totalPrice,
            product.basePrice,
            product.serviceFee,
            product.productPrice,
            product.taxValue,
            product.taxClass,
            product.deliveryUrl || '',
            product.isHidden,
            product.isPublished,
            product.showServiceFee,
            product.uiMajorColor,
            product.uiMinorColor,
            product.uiMajorTextColor,
            product.uiMinorTextColor,
            product.tpSuccessTitle,
            product.tpSuccessText,
            product.tpSuccessInstructionsText,
            product.tpSuccessInstructionsButton,
            product.tpShowShare,
            product.tpShowReceipt,
            product.tpShowInstructions,
            product.smsText,
            product.image,
            product.imageBody,
            product.prices,
            product.redirectUrl,
            product.phoneNumber
        ];

        try {
            // @ts-ignore
            product.id ? await apiClient.editProduct(product.id!, ...commonFields) : await apiClient.addProduct(...commonFields);

            setIsDataLoading(false);
            navigate(`/products`);
        } catch {
            setIsDataLoading(false);
        }
    };

    return (
        <div className="lift-body" id="sms-page">
            {isDataLoading && <Spinner/>}

            <div className="fix-btn-wrap">
                <NavLink className="main-button cancel-button" to="../thanks">{t('button.back')}</NavLink>
            </div>

            <div className="fix-btn-wrap fix-btn-wrap_right">
                <button className="main-button" onClick={saveProduct}>{t('button.save')}</button>
            </div>

            <div className="sm-lift sms-page">
                {product.type === 'coupon' &&
                    <div className="url-editor">
                        <h3 className="editor-text">{t('product.addDeliveryUrl')}</h3>
                        <TextareaAutosize
                            defaultValue={product.deliveryUrl ? product.deliveryUrl : 'https://'}
                            onChange={(e) => dispatch(setProductString({deliveryUrl: e.currentTarget.value}))}
                            className="text_editable url_editable"
                            autoFocus={!product.deliveryUrl}
                        />
                    </div>
                }

                <SmsTextEditor/>

                <div className="sms-page-cb">
                    <CommonInput
                        type={'phone'}
                        label={t('product.phone')}
                        labelAbove={true}
                        value={product.phoneNumber}
                        hint={t('product.hint.phone')}
                        onChange={(e) => {
                            dispatch(setProductString({phoneNumber: e.target.value || null}));
                        }}
                    />

                    <label className="cb big toggle mt-30">
                        <input
                            type="checkbox"
                            checked={!!product.isPublished}
                            onChange={() => dispatch(toggleProductFlag('isPublished'))}
                        />
                        <span>{t('product.published')}</span>
                    </label>

                    <label className="cb big toggle">
                        <input
                            type="checkbox"
                            checked={!!product.isHidden}
                            onChange={() => dispatch(toggleProductFlag('isHidden'))}
                        />
                        <span>{t('product.hidden')}</span>
                    </label>

                    {product.type === 'ticket' && <input
                        id="redirectUrl"
                        type="text"
                        className="sms-page-input"
                        placeholder={t('product.redirectUrl')}
                        value={product.redirectUrl || ''}
                        onChange={(e) => {
                            dispatch(setProductString({redirectUrl: e.currentTarget.value || null}));
                            document.getElementById('redirectUrl')!.className = 'sms-page-input';
                        }}
                    />}
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;