import './App.css';

import React, {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import PrivateRoute from './components/Routing/PrivateRoute';

import Header from './components/Header';
import ProductsPage from './pages/ProductsPage';
import CodesPage from './pages/CodesPage';
import MerchantRegistration from './pages/MerchantRegistration';
import ErrorModal from './components/ErrorModal';
import apiClient, {ApiClient} from './store/apiClient';
import config from './config';
import {useAuth0} from '@auth0/auth0-react';
import {setIconUrlVariables} from './pages/Product/slice';
import {useAppDispatch} from './redux/hooks';
import Product from './pages/Product';
import AdminPage from './pages/AdminPage';
import SettingsPage from './pages/SettingsPage';
import {APP_VERSION} from './buildInfo';

setIconUrlVariables();

function App() {
    const dispatch = useAppDispatch();
    const auth0 = useAuth0();

    const [apiVersion, setApiVersion] = useState<string | undefined>();

    useEffect(() => ApiClient.setAuth0(auth0), [auth0]);

    useEffect(() => ApiClient.setReduxDispatcher(dispatch), [dispatch]);

    useEffect(() => {
        (async () => {
            setApiVersion(auth0.isAuthenticated ? await apiClient.getApiVersion() : undefined);
        })()
    }, [auth0]);

    return (
        <BrowserRouter>
            <link rel="stylesheet" href={`${config.site.url}/font/Roboto/Roboto.css`}/>
            <link rel="stylesheet" href={`${config.site.url}/css/product.css`}/>
            <link rel="stylesheet" href={`${config.site.url}/css/order.css`}/>
            <link rel="stylesheet" href={`${config.site.url}/css/ticket.css`}/>
            <link rel="stylesheet" href={`${config.site.url}/css/voucher.css`}/>
            <link rel="stylesheet" href={`${config.site.url}/css/deactivator.css`}/>

            <div className="App">
                <Header/>

                <ErrorModal/>

                <Routes>
                    <Route element={<PrivateRoute/>}>
                        <Route path="products" element={<ProductsPage/>}/>

                        <Route path="product/:id/*" element={<Product/>}/>

                        <Route path="codes/:id" element={<CodesPage/>}/>

                        <Route path="register" element={<MerchantRegistration/>}/>

                        <Route path="admin/*" element={<AdminPage/>}/>

                        <Route path="settings" element={<SettingsPage/>}/>
                    </Route>

                    {auth0.isAuthenticated && <Route path="/" element={<Navigate to="/products" replace/>}/>}
                </Routes>

                <div className="version align-r">{apiVersion && `v${apiVersion}`} UI{APP_VERSION}</div>
            </div>
        </BrowserRouter>
    );
}

export default App;
