import './styles.css';
import 'intl-tel-input/styles';

import React, {ChangeEvent} from 'react';
import ContentEditable from 'react-contenteditable';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/reactWithUtils';


interface ComponentProps {
    label?: any;
    labelAbove?: boolean;
    type?: 'text' | 'date' | 'time' | 'email' | 'phone' | 'textarea' | 'htmlarea' | 'select';
    hint?: string;
    pattern?: string;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    value?: string | number | null;
    placeholder?: string;
    minlength?: number;
    maxlength?: number;
    options?: {name: string, value: string | number}[];
    onChange(e: Partial<ChangeEvent<any>>);
}

const CommonInput = ({label, labelAbove, hint, value, type, options, ...props}: ComponentProps) => <label className={`input-wrap ${labelAbove && 'input-wrap--label-above'}`}>
    {(!type || ['text', 'email', 'number'].includes(type)) && <input {...props} type={type} value={value ?? ''}/>}
    {(type === 'date') && <input {...props} type={type} value={value ?? ''} onFocus={(e) => e.target.showPicker()}/>}
    {(type === 'textarea') && <textarea {...props} value={value ?? ''}/>}
    {(type === 'phone') && <IntlTelInput initialValue={value} initOptions={{
        initialCountry: 'fi',
        countryOrder: ['fi'],
        formatOnDisplay: false
    }} onChangeNumber={(number: string) => {
        if (number !== value && (number !== '' || value !== null)) props.onChange({target: {value: number}})}} />}
    {(type === 'select') && <select {...props} value={value ?? ''}>
        {options?.map((i) => <option key={i.value} value={i.value}>{i.name}</option>)}
    </select>}
    {// @ts-ignore
        (type === 'htmlarea') && <ContentEditable{...props} html={`${value}`} data-invalid={props.required && !`${value}`.replace(/<[^>]+>/g, '').trim()}/>
    }
    <span>{label}</span>
    {hint && <i>{hint}</i>}
</label>;

export default CommonInput;