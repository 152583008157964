import './UploadResultArea.css';
import '../UploadArea/UploadArea.css';

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface UploadResultAreaProps {
    goodCodes: string;
    badCodes: string;
    id: number;
    onNewUpload: Function;
}

const UploadResultArea = ({goodCodes, badCodes, id, onNewUpload}: UploadResultAreaProps) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="upload-result-page">
                {goodCodes &&
                    <div className={badCodes ? 'upload-result-area_both' : 'upload-result-area'}>
                        <h3 className="upload-result-title upload-result-title_success">{t('codes.added')}</h3>
                        <textarea
                            value={goodCodes}
                            className="codes-textarea codes-textarea_success"
                            readOnly={true}
                        />
                    </div>
                }

                {badCodes &&
                    <div className={goodCodes ? 'upload-result-area_both' : 'upload-result-area'}>
                        <h3 className="upload-result-title upload-result-title_error">{t('codes.alreadyUsed')}</h3>
                        <textarea
                            value={badCodes}
                            className="codes-textarea codes-textarea_error"
                            readOnly={true}
                        />
                    </div>
                }
            </div>
            <div className={'upload-result-buttons'}>
                <button className={'btn btn-dark upload-result-link'} onClick={() => onNewUpload()}>
                    {t('codes.uploadNew')}
                </button>
                <Link to={'/'} className={'btn btn-success upload-result-link'}>{t('codes.back')}</Link>
            </div>
        </>
    );
};

export default UploadResultArea;