import styles from './styles.module.css';

import React from 'react'

interface Props {
    href?: string;
    icon?: string;
    text?: string;
    inNewTab?: boolean;
    onClick?(e: any);
}

const Link = ({href, inNewTab, icon, text, onClick}: Props) => <a
    href={href} onClick={onClick}
    target={inNewTab ? '_blank' : undefined}
    className={styles.link + (icon && styles[icon] ? ` ${styles.icon} ${styles[icon]}` : '')}
>{text}</a>;

export default Link;