import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchProducts} from './slice';
import {NavLink, useNavigate} from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import {DEF_VATS} from '../../Product/slice';
import apiClient from '../../../store/apiClient';
import VoucherGenerator from '../../../components/VoucherGenerator';

const ProductsPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {loading, list} = useAppSelector((state) => state.adminProducts);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isVoucherGenerator, setIsVoucherGenerator] = useState(false);

    const getVouchersPdf = async () => {
        setIsDataLoading(true);

        try {
            await apiClient.getVouchersPdf();
        } catch (e) {

        } finally {
            setIsDataLoading(false);
        }
    };

    const genStatus = (product: IProduct) => {
        if (product.isHidden) return <b>{t('product.status.hidden')}</b>;
        if (product.isPublished) return t('product.status.published');
        return <b>{t('product.status.pending')}</b>;
    };

    useEffect(() => {
        dispatch(fetchProducts());
    }, []);

    return <>
        {(loading || isDataLoading) && <Spinner/>}

        {isVoucherGenerator && <VoucherGenerator
            onCancel={() => setIsVoucherGenerator(false)}
            onSave={() => {
                setIsVoucherGenerator(false);
                dispatch(fetchProducts());
            }}
        />}

        <div style={{padding: '10px 20px', display: 'flex', gap: '10px', justifyContent: 'space-between'}}>
            <NavLink to={'0'} className="main-link" style={{marginRight: 'auto'}}>{t('products.add')}</NavLink>
            <a className="main-link" onClick={() => getVouchersPdf()}>{t('button.getVouchers')}</a>

            <a className="main-link" onClick={() => setIsVoucherGenerator(true)}>{t('button.genVouchers')}</a>
        </div>

        <table className="table-list">
            <thead>
            <tr>
                <th>{t('product.url')}</th>
                <th>{t('product.name')}</th>
                <th>{t('product.type')}</th>
                <th>{t('product.price')}</th>
                <th>{t('product.vat')}</th>
                <th>{t('product.status.')}</th>
            </tr>
            </thead>
            <tbody>
            {list.map((i, k) => <tr key={k} onClick={() => navigate(`${i.id}`)}>
                <td>{i.urlName}</td>
                <td>{i.name}</td>
                <td>{i.type}</td>
                <td>{i.totalPrice}</td>
                <td>{DEF_VATS[i.taxClass]}%</td>
                <td>{genStatus(i)}</td>
            </tr>)}
            </tbody>
        </table>
    </>;
};

export default ProductsPage;