import './Header.css';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {NavDropdown} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {DEF_LANGS, setLang, updateLogo} from '../../redux/appSlice';

const Header = () => {
    const {t} = useTranslation();
    const {isLoading, isAuthenticated, loginWithRedirect, logout} = useAuth0();
    const dispatch = useAppDispatch();
    const merchant = useAppSelector((state) => state.app.merchant);

    const [logo, setLogo] = useState(merchant?.logo);
    const [logoUpdatable, setLogoUpdatable] = useState<boolean>(!!merchant);
    const [isAdmin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (merchant) {
            setLogoUpdatable(true);
            setLogo(merchant.logo || '/android-chrome-192x192.png');
            setAdmin(merchant.permissions.some((i) => i.startsWith('data')));
        }
    }, [merchant]);

    const loadImage = (e) => {
        const file = e.target.files[0];

        if (!merchant?.id || !file || !['image/png', 'image/svg+xml'].includes(file.type)) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            dispatch(updateLogo({
                logo: file.name,
                logoBody: reader.result,
            }));
        };
    };

    return (
        <nav className="navbar d-flex justify-content-between">
            <div className="d-flex align-items-center navbar-logo-wrap">
                <label
                    className={`navbar-logo-logo ${logoUpdatable ? 'editable' : ''}`}
                    style={{'backgroundImage': logo ? `url(${logo})` : '/android-chrome-192x192.png'}}
                >
                    {logoUpdatable && <input
                        type="file"
                        accept="image/png,image/svg+xml"
                        className="navbar-logo-updater"
                        onChange={loadImage}
                    />}
                </label>
                <Link to="/" className="navbar-logo">{t('header.shop')}</Link>
            </div>

            <NavDropdown
                className=""
                title={<span className="navbar-company">{t('header.changeLang')}</span>}
                id="basic-nav-dropdown">

                {DEF_LANGS.map(i => <NavDropdown.Item key={i.id} onClick={() => dispatch(setLang(i.id))}>{t('header.lang.' + i.id)}</NavDropdown.Item>)}
            </NavDropdown>

            <div className="navbar-links">
                {isAdmin && <>
                    <Link to="admin/transactions">{t('header.admin')}</Link>
                    <Link to="products">{t('header.productList')}</Link>
                </>}

                <div>
                    {!isLoading && !isAuthenticated && <button className="btn btn-outline-primary btn-auth" onClick={() => loginWithRedirect({})}>{t('button.login')}</button>}
                    {isAuthenticated && <button className="btn btn-outline-primary btn-auth" onClick={() => logout({returnTo: window.location.origin})}>{t('button.logout')}</button>}
                </div>
            </div>
        </nav>
    );
};

export default Header;
