import './ErrorModal.css';

import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {clearError} from '../../redux/appSlice';

const ErrorModal = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const {error} = useAppSelector((state) => state.app);

    return useMemo(() => {
        return !error ? null : (
            <div className="sm-overlay">
                <div className="sm-popup">
                    <button type="button" className="sm-popup-close" onClick={() => dispatch(clearError())}/>

                    <div className="sm-popup-icon sm-popup-icon-fail"/>

                    <div className="sm-popup-title">{t('oops')}</div>

                    <div className="sm-popup-subtitle">{error}</div>
                </div>
            </div>
        );
    }, [error, dispatch]);
};

export default ErrorModal;