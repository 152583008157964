import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ContentState, convertFromHTML, Editor, EditorState} from 'draft-js';
import {useAppSelector} from '../../../../redux/hooks';
import Receipt from '../../../../components/Receipt';


const VoucherPage = () => {
    const {t} = useTranslation();

    const merchant = useAppSelector((state) => state.app.merchant!);
    const product: IProduct = useAppSelector((state) => state.product.product!);

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // watch for changes in product description and set new Editor state
    useEffect(() => {
        const HTMLDescription = convertFromHTML(product.description);
        const editedTextState = ContentState.createFromBlockArray(
            HTMLDescription.contentBlocks,
            HTMLDescription.entityMap,
        );
        setEditorState(() => EditorState.createWithContent(editedTextState));
    }, [product.description]);

    return (
        <div className="lift-body">
            <div className="sm-lift">
                <div className="sm-floor sm-floor-flex sm-floor-voucher" id="ticket">
                    <div className="sm-order-voucher activated" style={{backgroundImage: `url(${product.image})`}}>
                        <div className="sm-order-voucher-inner">
                            <div className="sm-order-voucher-head">
                                <div className="sm-order-voucher-name">{product.name}</div>
                                <div className="sm-order-voucher-title">{t('voucher.title')}</div>
                            </div>

                            <div className="sm-order-voucher-qr">
                                <div className="sm-order-qr" style={{backgroundImage: 'url("/images/qr-code.svg")'}}/>
                            </div>

                            <div className="sm-order-voucher-controls">
                                <div className="sm-voucher-timer-wrap">
                                    <div className="sm-order-voucher-price">{product.productPrice}&nbsp;€</div>

                                    <div className="sm-order-activation-timer">
                                        <div className="countdown">
                                            <div data-ts="1665857447">0:30</div>
                                        </div>
                                    </div>

                                    <div className="sm-deactivator-hint">
                                        {t('voucher.activated')} <b>{t('voucher.time')}</b>
                                        <br/>
                                        {t('voucher.willBeValid')} <b>{t('voucher.minutes')}</b>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="sm-fuudo-logo"/>

                                <div className="sm-next-wrap">
                                    <a className="sm-voucher-next" href="#description">
                                        <span className="sm-voucher-next-arrow"/>
                                        <span className="sm-voucher-next-arrow"/>
                                        <span className="sm-voucher-next-arrow"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sm-floor" id="description">
                    <div className="sm-product-panel">
                        <div className="sm-product-description">
                            <Editor editorState={editorState}/>
                        </div>

                        <div>
                            <a className="sm-voucher-next" href="#receipt">
                                <span className="sm-voucher-next-arrow"/>
                                <span className="sm-voucher-next-arrow"/>
                                <span className="sm-voucher-next-arrow"/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="receipt">
                    <Receipt product={product} merchant={merchant}/>
                </div>
            </div>
        </div>
    );
};

export default VoucherPage;