import React from 'react';
import {useTranslation} from 'react-i18next';
import {DEF_VATS} from '../../pages/Product/slice';

interface ReceiptProps {
    product: IProduct;
    merchant: IMerchant;
}

const Receipt = ({product, merchant}: ReceiptProps) => {
    const {t} = useTranslation();

    return (
        <div className="sm-order-receipt-wrap" id="capture">
            <div className="sm-order-receipt">
                <div className="sm-order-receipt-logo"
                     style={{backgroundImage: `url(${merchant.logo})`}}/>

                <div className="sm-order-receipt-title">{t('receipt.storeName', {name: merchant.name})}</div>

                <div className="sm-order-receipt-line">
                    <span>24.4.2024</span>
                    <span>10:07</span>
                </div>

                <div className="sm-order-receipt-separator"/>

                <div className="sm-order-receipt-line">
                    <span>{product.name}</span>
                    <span>{product.showServiceFee ? product.productPrice : product.totalPrice}&euro;</span>
                </div>

                {!!product.showServiceFee &&
                    <div className="sm-order-receipt-line">
                        <span>{t('receipt.mobileFee')}</span>
                        <span>{product.serviceFee}&euro;</span>
                    </div>
                }

                <div className="sm-order-receipt-separator"/>

                <div className="sm-order-receipt-line">
                    <span>{t('receipt.vat', {vat: DEF_VATS[product.taxClass]})}</span>
                    <span>{product.taxValue}</span>
                </div>

                <div className="sm-order-receipt-separator"/>

                <div className="sm-order-receipt-line">
                    <span>{t('receipt.total')}</span>
                    <span>{product.totalPrice}&euro;</span>
                </div>

                <div className="sm-order-receipt-button">
                    <button type="button" id="capture_btn"
                        className="sm-button sm-button-action sm-button-capture"
                    >{t('button.save')}</button>
                </div>
            </div>
        </div>
    );
};

export default Receipt;