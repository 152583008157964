import './HTMLAndTextEditor.css';

import React, {useEffect, useState} from 'react';

import {Editor, EditorState, RichUtils} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';

import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setProductString} from '../../pages/Product/slice';

interface HTMLAndTextEditorProps {
    editedProductField: string;
}

const HTMLAndTextEditor = ({editedProductField}: HTMLAndTextEditorProps) => {
    const dispatch = useAppDispatch();
    const product: IProduct = useAppSelector((state) => state.product.product!);
    const [isEdit, setIsEdit] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // watch for changes in product description and set new Editor state
    useEffect(() => {
        const editedTextState = stateFromHTML(product[editedProductField]);
        setEditorState(() => EditorState.createWithContent(editedTextState));
    }, [product[editedProductField]]);

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onBoldClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };

    const onItalicClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };

    const onUnderlineClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    };

    return (
        <div className={`${editedProductField}_editable editable`}
             onFocus={() => setIsEdit(true)}
             onBlur={(event) => {
                 const relatedTarget: any = event.relatedTarget;
                 if (!event.relatedTarget || !event.currentTarget.contains(relatedTarget)) {
                     dispatch(setProductString({[editedProductField]: stateToHTML(editorState.getCurrentContent())}))
                     setIsEdit(false);
                 }
             }}
        >
            {isEdit &&
                <div style={{marginBottom: '10px'}}>
                    <button className="btn btn-editor" style={{fontWeight: 'bold'}} onClick={() => onBoldClick()}>B
                    </button>
                    <button className="btn btn-editor" style={{fontStyle: 'italic'}} onClick={() => onItalicClick()}>I
                    </button>
                    <button className="btn btn-editor" style={{textDecoration: 'underline'}}
                            onClick={() => onUnderlineClick()}>U
                    </button>
                </div>
            }
            <Editor
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={handleKeyCommand}
                textAlignment={editedProductField === 'tpSuccessInstructionsText' ? 'center' : 'left'}
            />
        </div>
    );
};

export default HTMLAndTextEditor;
