import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiClient from '../../../store/apiClient';

interface SliceType {
    loading: boolean,
    list: ITransfer[];
}

const initialState: SliceType = {
    loading: false,
    list: [],
};

export const fetchTransfers = createAsyncThunk('transfers/fetch', async (): Promise<ITransfer[]> => {
    return await apiClient.getTransfers();
});

const slice = createSlice({
    name: 'adminTransfersSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchTransfers.pending, (state) => {
                state.list = [];
                state.loading = true;
            })
            .addCase(fetchTransfers.fulfilled, (state, action) => {
                state.list = action.payload;
                state.loading = false;
            })
            .addCase(fetchTransfers.rejected, (state) => {
                state.loading = false;
            })
    },
});

export default slice.reducer;