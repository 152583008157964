import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiClient from '../../../store/apiClient';

interface SliceType {
    loading: boolean,
    data: ICompany;
}

export const genDefCompany = ():ICompany => ({
    id: 0,
    name: '',
    code: '',
    address: '',
    email: '',
    bankName: '',
    bankAddress: '',
    bankCountry: '',
    accountHolder: '',
    iban: '',
    bic: '',
    currency: 'EUR',
});

const initialState: SliceType = {
    loading: false,
    data: genDefCompany(),
};

export const fetchCompany = createAsyncThunk('company/fetch', async (id: number): Promise<ICompany> => {
    return id === 0 ? genDefCompany() : await apiClient.getCompany(id);
});

const slice = createSlice({
    name: 'adminCompanySlice',
    initialState,
    reducers: {
        resetCompanyEditData: (state) => {
            state.data = genDefCompany();
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCompany.pending, (state) => {
                state.data = genDefCompany();
                state.loading = true;
            })
            .addCase(fetchCompany.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchCompany.rejected, (state) => {
                state.loading = false;
            })
    },
});

export const {resetCompanyEditData} = slice.actions;

export default slice.reducer;