import styles from './styles.module.css';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompanies} from './slice';
import Spinner from '../../../components/Spinner';
import CommonInput from '../../../components/CommonInput';
import apiClient from '../../../store/apiClient';

const ReportsPage = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {loading, companies} = useAppSelector((state) => state.adminReports);
    const [from, setFrom] = useState<string>(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
    const [to, setTo] = useState<string>(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    const [companyId, setCompanyId] = useState<number | undefined>();
    const [productOwner, setProductOwner] = useState<string | undefined>();

    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);

    const handleFormSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (productOwner) {
            await apiClient.getProductOwnerReport(companyId!, productOwner, from, to);
        } else if (companyId) {
            await apiClient.getOrdersReport(companyId, from, to);
        } else {
            await apiClient.getRevenueReport(from, to);
        }
    };

    return <>
        {loading && <Spinner/>}

        <form onSubmit={handleFormSubmit}>
            <div className={styles.form}>
                <CommonInput
                    required={true}
                    type={'date'}
                    label={t('report.from')}
                    labelAbove={true}
                    value={from || ''}
                    onChange={(e) => setFrom(e.target.value || undefined)}
                />

                <CommonInput
                    required={true}
                    type={'date'}
                    label={t('report.to')}
                    labelAbove={true}
                    value={to || ''}
                    onChange={(e) => setTo(e.target.value || undefined)}
                />

                <CommonInput
                    type={'select'}
                    required={!!productOwner}
                    label={t('report.paymentAccount')}
                    labelAbove={true}
                    value={companyId || ''}
                    onChange={(e) => setCompanyId(+e.target.value || undefined)}
                    options={[
                        {value: '', name: t('select.noCompany')},
                        ...companies.map((i) => ({value: i.id, name: i.name})),
                    ]}
                />

                <CommonInput
                    type={'text'}
                    label={t('report.productOwner')}
                    labelAbove={true}
                    value={productOwner || ''}
                    onChange={e => setProductOwner(e.target.value || undefined)}
                />
            </div>

            <div className="buttons-wrap" style={{marginBottom: '40px'}}>
                <button type="submit" className="main-button size-normal"
                    disabled={!companyId || !!productOwner}
                >{t('button.generate.orders')}</button>
                <button type="submit" className="main-button size-normal"
                    disabled={!!companyId || !!productOwner}
                >{t('button.generate.revenue')}</button>
                <button type="submit" className="main-button size-normal"
                    disabled={!productOwner}
                >{t('button.generate.pOwner')}</button>
            </div>
        </form>
    </>;
};

export default ReportsPage;