import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import Settings from './Settings';
import Thanks from './Thanks';
import Type from './Type';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setEditProduct} from './slice';

const Product = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams();

    const {merchant, product} = useAppSelector((state) => ({
        merchant: state.app.merchant!,
        product: state.product.product
    }));

    useEffect(() => {
        dispatch(setEditProduct({id, merchant}));
    }, [id, merchant]);

    return !product ? null : <>
        <style type="text/css">
            :root {`{
                --major-color: #${product.uiMajorColor};
                --minor-color: #${product.uiMinorColor};
                --minor-color--light: rgba(${product.uiMinorColorLightRGBA});
                --major-text-color: #${product.uiMajorTextColor};
                --minor-text-color: #${product.uiMinorTextColor};
                --bg-image-height: 20vh;
            }`}
        </style>
        <Routes>
            <Route index element={<Navigate to="type" replace/>}/>
            <Route path="type" element={<Type/>}/>
            <Route path="thanks" element={<Thanks/>}/>
            <Route path="settings" element={<Settings/>}/>
        </Routes>
    </>;
};

export default Product;
