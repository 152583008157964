import './Spinner.css';

import React from 'react';

const Spinner = () => {
    return (
        <div className="spinner">
            <div className="lds-grid">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};

export default Spinner;

